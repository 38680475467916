import React from "react";

const VideoBackgroundWidget = props => {
  const backgroundVideoUrl = props.url.replace('youtu.be', 'www.youtube.com/embed')
    .replace('watch?v=', 'embed/')
    .replace('vimeo.com', 'player.vimeo.com/video') + '?&autoplay=1&loop=1&controls=0&showinfo=0&muted=1'
  return (
    <div className='hero-video'>
      <iframe
        frameborder="0"
        height="100%"
        width="100%"
        webkitAllowFullScreen mozallowfullscreen allowFullScreen
        allow='autoplay; encrypted-media'
        src={backgroundVideoUrl}>
      </iframe>
    </div>
  )
}

export default VideoBackgroundWidget;