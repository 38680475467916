import React from "react";
import { BeatLoader } from 'react-spinners';

const Loader = props => {
  const isPTP = window?.location.pathname.includes('power-to-protect')
  return (
    <div className='btn-load-more'>
      {props.loading ? <BeatLoader loading={props.loading} color={isPTP ? '#bb6a0b' : '#4B5C93'} /> : <button onClick={props.loadMore}>{ props.title }</button>}
  </div>
   )
}

export default Loader;
