import React, { Component } from 'react'
import map from 'lodash/map'
import { buildURL } from '../../utils/url'
import { navigate } from 'gatsby'
import { Link } from 'gatsby'
import EventTracker from './../../helpers/EventTracker'
import { SearchBar } from '../common'
import VideoBackgroundWidget from './VideoBackgroundWidget'

export default class HeroWidget extends Component {
    constructor(props) {
        super(props)

        this.state = {
            visible: false,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                visible: true,
            })
        }, 1000)
    }

    render() {
        const { className, activeSection, fullWidth, isIE11 } = this.props
        const { title, summary, backgroundImage, backgroundVideoUrl, featureList, link, type, searchText, linkColour } = this.props.data

        return (
            <div className="hero-widget">
                <div className={`hero ${className || ''} ${type}`}>
                    {this.state.visible && this.renderMedia(backgroundImage, backgroundVideoUrl, isIE11)}
                    <div className={`hero-main hero-main-video`}>
                        <div className="inner">
                            <div className="hero-content">
                                <h1>{title}</h1>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: summary.childContentfulRichText.html,
                                    }}
                                />
                                {link && (
                                    <a href={link.url} className="hero-link" style={{ background: linkColour ? linkColour : '#485C97' }} onClick={() => this.linkUrl(link.url)}>
                                        {link.text}
                                        <i className="far fa-chevron-right" />
                                    </a>
                                )}
                                {activeSection === 'Safekids' ? (
                                    <div className="searchbox-wrapper">
                                        <p className="search-text">{searchText ? searchText : null}</p>
                                        <SearchBar id={'searchbar-panel-widget'} activeSection={activeSection} withOverlay={false} alwaysActive={true} />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderFeatureList(featureList, type, true, fullWidth)}
            </div>
        )
    }

    linkUrl(url) {
        EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.HERO_WIDGET, EventTracker.EVENT_TYPES.CLICK, 'DESCRIPTION')
    }

    renderMedia(backgroundImage, backgroundVideoUrl, isIE11) {
        if (isIE11) {
            return this.renderImage(backgroundImage)
        }
        return this.selectRenderMedia(backgroundImage, backgroundVideoUrl)
    }

    selectRenderMedia(backgroundImage, backgroundVideoUrl) {
        if (backgroundVideoUrl) {
            return this.renderVideo(backgroundVideoUrl)
        }
        return this.renderImage(backgroundImage)
    }

    renderImage(backgroundImage) {
        return <div className="hero-image" role="img" aria-label={backgroundImage.title} style={{ backgroundImage: `url(${backgroundImage.file.url})` }}></div>
    }

    renderVideo(backgroundVideoUrl) {
        return <VideoBackgroundWidget className={'hero-video'} url={backgroundVideoUrl} />
    }

    getStoryBlockFeatureLink(feature) {
        return feature.story || feature.internalHyperlink || feature.externalHyperlink
    }
    renderFeatureList(featureList, type, isVideo, fullWidth) {
        return (
            featureList && (
                <div className={`features hero-features ${isVideo ? 'hero-features-video' : ''} ${fullWidth ? 'full-width' : ''}`}>
                    <ul>
                        {map(featureList, (feature) => {
                            let params = {}

                            if (feature.__typename === 'ContentfulWidgetStoryBlock' || feature.__typename === 'ContentfulWidgetStoryBlockPtp') {
                                const link = this.getStoryBlockFeatureLink(feature)

                                params = {
                                    isInternal: true,
                                    key: feature.slug,
                                    link: buildURL(link),
                                    linkText: feature.title,
                                    backgroundImage: feature.backgroundImage.file.url,
                                    backgroundImageTitle: feature.backgroundImage.title,
                                    icon: 'fa-heart',
                                }
                            } else {
                                // Check if featureLink is null, an array, or a single element
                                const featureLinkArray = Array.isArray(feature.featureLink) ? feature.featureLink : feature.featureLink ? [feature.featureLink] : []

                                if (featureLinkArray.length > 0 && (featureLinkArray[0]?.__typename !== 'ContentfulWidgetLink' || featureLinkArray[0]?.__typename !== 'ContentfulWidgetLinkPtp')) {
                                    params = {
                                        isInternal: true,
                                        key: feature.slug,
                                        link: buildURL(featureLinkArray[0]),
                                        linkText: feature.title,
                                        backgroundImage: feature.backgroundImage.file.url,
                                        backgroundImageTitle: feature.backgroundImage.title,
                                        icon: feature.icon || null,
                                    }
                                } else if (featureLinkArray.length > 0) {
                                    params = {
                                        isInternal: false,
                                        key: feature.slug,
                                        link: featureLinkArray[0].url,
                                        linkText: feature.title,
                                        backgroundImage: feature.backgroundImage.file.url,
                                        icon: feature.icon || null,
                                    }
                                }
                            }

                            if (type === 'Story') {
                                return this.getStoryFeature(params)
                            } else if (type === 'Link') {
                                return this.getLinkFeature(params)
                            }
                        })}
                    </ul>
                </div>
            )
        )
    }

    /*
     * Show an image block, when mouse hover show a shade and title of the block
     */
    getStoryFeature({ isInternal, key, link, linkText, backgroundImage, backgroundImageTitle }) {
        return (
            <li key={key}>
                {isInternal ? (
                    <Link to={link} className="feature hero-feature" onClick={() => EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.HERO_WIDGET, EventTracker.EVENT_TYPES.CLICK, 'STORY')} role="img" aria-label={backgroundImageTitle} style={{ backgroundImage: `url("${backgroundImage}")` }}>
                        <div className="overlay" />
                        <span>{linkText}</span>
                    </Link>
                ) : (
                    <a href={link} className="feature hero-feature" onClick={() => EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.HERO_WIDGET, EventTracker.EVENT_TYPES.CLICK, 'STORY')} role="img" aria-label={backgroundImageTitle} style={{ backgroundImage: `url("${backgroundImage}")` }}>
                        <div className="overlay" />
                        <span>{linkText}</span>
                    </a>
                )}
            </li>
        )
    }

    /*
     * Show an link block, with title and an optional icon
     */
    getLinkFeature({ isInternal, key, link, linkText, icon }) {
        return (
            <li key={key}>
                {isInternal ? (
                    <Link onClick={() => EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.HERO_WIDGET, EventTracker.EVENT_TYPES.CLICK, 'LINK')} to={link} className="feature hero-feature-link">
                        <span>{linkText}</span>
                        {icon && <i className={`fal ${icon} icon`} />}
                    </Link>
                ) : (
                    <a onClick={() => EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.HERO_WIDGET, EventTracker.EVENT_TYPES.CLICK, 'LINK')} href={link} className="feature hero-feature-link">
                        <span>{linkText}</span>
                        {icon && <i className={`fal ${icon} icon`} />}
                    </a>
                )}
            </li>
        )
    }
}
