import React, { Component } from 'react'
import Panel from './../common/Panel'
import { withGuidelineContext } from '../../contexts'

class GuidelineFeedback extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageTitle: '',
            pageUrl: '',
            loaded: false,
        }
    }

    componentDidMount() {
        this.setState({
            pageUrl: window.location.href,
            loaded: true,
        })
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            pageTitle: props.guidelineContext.info.title,
            editor: props.guidelineContext.info.editor,
        }
    }

    render() {
        const { pageTitle, pageUrl, loaded, editor } = this.state
        const editorName = `${editor && editor.firstName ? editor.firstName : ''} ${editor && editor.lastName ? editor.lastName : 'NA'}`

        return (
            <Panel title="Guideline Feedback" className="guideline-feedback" id="guideline-feedback">
                <div className="col-sm-12 col-md-6 col">{loaded && <iframe title="feedback" className="iframe" src={`https://fs4.formsite.com/PuOTCh/ne6jxbqbz6/fill?1=${pageTitle}&2=${pageUrl}&4=${editorName}`}></iframe>}</div>
            </Panel>
        )
    }
}

export default withGuidelineContext(GuidelineFeedback)
