import React, { PureComponent } from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { Header, Footer } from '../common'
import { StoryBlockWidget } from '../widgets'
import { SECTIONS } from '../../utils/url'
import Share from './../share/Share'

import '../../assets/styles/app.scss'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import richTextReactRendering from '../../utils/richTextReactRendering';
import VideoBackgroundWidget from '../widgets/VideoBackgroundWidget'
import { addEmbedScript, addHeadScript } from '../../utils/import-embed-script'
import { defaultFacebookPixel, safekidsFacebookPixel } from '../common/FacekookPixel'

export default class StoryPageLayout extends PureComponent {
    state = {
        isMobile: false,
    }

    componentDidMount = () => {
        addEmbedScript();
        addHeadScript(defaultFacebookPixel)
        if (this.props.story.userSection) {
            addHeadScript(safekidsFacebookPixel)
        }
        this.setState({
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
        })
    }

    render() {
        const { story, site, preview, sitemap } = this.props
        const pageDonation = story? story.pageDonation : null
        const backgroundGradient = 'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.35) 100%)'
        const userSection = get(story, 'userSection')
        const backgroundUrl = get(story, 'heroImage.file.url', '')
        const backgroundVideoUrl = get(story, 'heroVideo', '')
        const heroImageFullWidth = get(story, 'heroImageFullWidth')
        const backgroundTitle = get(story, 'heroImage.title', '')
        const body = preview ? story.content : JSON.parse(story.content.childContentfulRichText.internal.content)

        // Instead of defining it's own header, footer and other common layouts, it should extend from PageLayout
        // It's bad, very bad, you shouldn't create new layout with this format
        //
        // I'm forced to write this down - Someone
        return story && (
            <div className={'app-root story-page'}>
                <Helmet>
                    <title>{site.title} - {story.title}</title>
                    {!!story.metaDescription && <meta name="description" content={story.metaDescription.metaDescription} />}
                </Helmet>
                <Header activeSection={userSection ? userSection : SECTIONS.SUPPORT_STARSHIP.key} />
                <div className={!heroImageFullWidth ? "no-full-width" : ""}>
                    {
                        backgroundVideoUrl ? <div className='story-hero-banner'><VideoBackgroundWidget url={backgroundVideoUrl} /></div> :
                        <div className="story-hero-banner" role="img" aria-label={backgroundTitle} style={{ backgroundImage: `${backgroundGradient}, url(${backgroundUrl})` }} />
                    }
                </div>
                <main className="wrapper has-sidebar">
                    {
                        story.storyBlock && <aside className="sidebar">
                            <StoryBlockWidget data={story.storyBlock} preview={preview} pageDonation={pageDonation} />
                        </aside>
                    }
                    <div className="content">
                        <h2>{story.title}</h2>
                        <div className="richtext-body">
                            {body && documentToReactComponents(body, richTextReactRendering)}
                        </div>
                        {story.showSocialShare && (
                            <Share
                                socialConfig={{
                                    twitterHandle: site.twitterHandle, 
                                    config: {
                                        url: `${site.siteUrl}/${story.slug}`,
                                        title: `${site.title} - ${story.title}`
                                    },
                                }}
                                tags={['Starship']}
                            />
                        )}
                    </div>
                </main>
                <Footer activeSection={SECTIONS.SUPPORT_STARSHIP.key} sitemap={sitemap} />
            </div>
        )
    }
}