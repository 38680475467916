import React, {Component} from 'react';
import get from 'lodash/get'

export default class EmbeddedWidget extends Component {
    render(){
        const {data} = this.props;
        const title = data.title;
        const type = data.type;
        const urlOrCode = data.url;
    
        if (type.toLowerCase() === 'url') {
            const url = urlOrCode.replace('youtu.be', 'www.youtube.com/embed')
                .replace('watch?v=', 'embed/')
                .replace('vimeo.com', 'player.vimeo.com/video');
            
            return (
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                        title={title}
                        className="embed-responsive-item"
                        src={url}
                        frameBorder="0"
                        allowFullScreen
                    />
                </div>
            );
        }
        const regexp = RegExp('src="(.*?)"', 'g');
    
        const src = regexp.exec(urlOrCode);
    
        if (src == null || src[1] == null) {
            return 'Please check ScreenCast embed code';
        }
    
        return (
            <div className="embed-responsive embed-responsive-16by9">
                <iframe
                    title={title}
                    class="embed-responsive-item"
                    src={src[1]}
                    frameBorder="0"
                    allowFullScreen
                />
            </div>
        );
    };
};
