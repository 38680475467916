import React, { PureComponent } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { withGuidelineContext, withPopupContext } from '../../contexts'

class SharePopup extends PureComponent {
    constructor() {
        super()
        this.state = {
            copied: false,
        }
    }

    render() {
        const url = window.location.href
        const pageTitle = document.title

        return (
            <div className="col">
                <div className="row">
                    <a
                        href={`mailto:?subject=Starship - ${pageTitle}&body=Check this guide on Starship Website: ${url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link file"
                    >
                        <span className="icon-box">
                            <i className="far fa-envelope" />
                        </span>
                        <span className="content-box">Send by email</span>
                    </a>
                    <CopyToClipboard
                        text={url}
                        onCopy={() => {
                            this.setState(
                                {
                                    copied: true,
                                },
                                () => {
                                    setTimeout(() => {
                                        this.setState({
                                            copied: false,
                                        })
                                    }, 3500)
                                }
                            )
                        }}
                    >
                        <div className="link file">
                            <span className="icon-box">
                                <i className="far fa-copy" />
                            </span>
                            <span className="content-box">Copy to clipboard</span>
                            {this.state.copied && (
                                <div className="badge badge-category copy">
                                    <span>Copied!</span>
                                </div>
                            )}
                        </div>
                    </CopyToClipboard>
                </div>
            </div>
        )
    }
}

export default withPopupContext(withGuidelineContext(SharePopup))
