import React from 'react'
import Panel from '../../common/Panel'
import map from 'lodash/map'
import InformationItem from '../../common/InformationItem'

export default (props) => {
    const { title, id, items, className } = props;

    if(!items) {
        return null
    }

    const halfLength = Math.ceil(items.length/2)
    const firstHalf = items.slice(0, halfLength)
    const secondHalf = items.slice(halfLength)

    return (
        <Panel title={title} id={id} className={className}>
            <div className="row">
                <div className="col">
                    {map(firstHalf, (information, i) => (
                        <InformationItem key={i} information={information} />
                    ))}
                </div>
                <div className="col">
                    {map(secondHalf, (information, i) => (
                        <InformationItem key={i} information={information} />
                    ))}
                </div>
            </div>
        </Panel>
    )
}