import React, { PureComponent } from 'react'
import ReactTooltip from 'react-tooltip'
import isUndefined from 'lodash/isUndefined'
import { scroller } from 'react-scroll';
import { Header, Footer, Overlay, ToolsItems } from '../common/index'
import {
    GuidelineSidebar as Sidebar,
    GuidelineContent as Content,
    GuidelineIndexList as IndexList,
    PopupManager,
} from '../guideline/index'
import { withScrollContext, withPopupContext } from '../../contexts/index'

import Background from '../../assets/images/draft.png'
import '../../assets/styles/widgets/_imageSmartWidget.scss'
import { SECTIONS } from '../../utils/url'
import { addEmbedScript, addHeadScript } from '../../utils/import-embed-script'
import get from 'lodash/get'
import { defaultFacebookPixel } from '../common/FacekookPixel';

class GuidelineLayout extends PureComponent {
    constructor(props) {
        super(props)
        
        this.state = {
            isSearchFocus: false,
            isOverlayActive: false,
            popupShowing: null,
            guidelineContent: null,
            isMobile: false,
            isBookmarked: this.isBookmarked(props)
        }
    }

    componentDidMount = () => {
        setTimeout(() => {
            addEmbedScript();
        }, 5000)
        addHeadScript(defaultFacebookPixel)
        this.setState({
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
        })
        const { location } = this.props;
        const hash = get(location, 'hash');
        if (hash) {
            const anchor = location.hash.split('#');
            scroller.scrollTo(anchor[1], {
                duration: 400,
                smooth: 'easeInOutQuint',
                containerId: anchor[1],
                offset: 0
            });
        }

        

        const props = this.props
        setTimeout(() => {
            const mobileFloatingBar = !isUndefined(document) && document.querySelector('#mobile-floating-bar')
            const topPos = this.state.isMobile ? mobileFloatingBar.offsetTop : 160
            props.scroll.setTopPos(topPos)
        }, 1500)

        setTimeout(() => {
            const appFooterTop = !isUndefined(document) && document.querySelector('.app-footer').offsetTop - window.innerHeight + 220

            props.scroll.setBotPos(appFooterTop)
        }, 5000)
    }
    isBookmarked = (props) => {
        if (typeof window !== 'undefined') {
            const bookmarks = JSON.parse(localStorage.getItem('bookmarks')) || [];
            const { location } = props || this.props;
            const pathname = get(location, 'pathname');
            const value = bookmarks.some(bookmark => pathname.includes(bookmark.slug));
            return value
        }
        return false;
    }

    onBookmarkClick = (guidelineContext) => {
        const isBookmarked = this.isBookmarked();
        const bookmarks = JSON.parse(localStorage.getItem('bookmarks')) || [];
        const { location } = this.props;
        const pathname = get(location, 'pathname');
        if (isBookmarked) {
            const bookmarkIndex = bookmarks.findIndex(bookmark => pathname.includes(bookmark.slug));
            bookmarks.splice(bookmarkIndex, 1);
            localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
        } else {
            const guideline = {
                title: get(guidelineContext, 'info.title'),
                slug: get(guidelineContext, 'info.slug'),
                categories: get(guidelineContext, 'info.categories'),
                guidelineType: get(guidelineContext, 'info.guidelineType'),
                isBookmarked: true,
                searchRedirectUrl: get(guidelineContext, 'info.searchRedirectUrl'),
                serviceProvider: get(guidelineContext, 'info.serviceProvider')
            }
            bookmarks.push(guideline);
            localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
        }
        this.setState({
            isBookmarked: !this.state.isBookmarked
        });
    }
    render() {
        const { isMobile, isBookmarked } = this.state

        const { preview, scroll, location, sitemap } = this.props

        const { togglePopup, POPUP_ID } = this.props.popupContext

        const floatingClass = scroll.isFixedIndex ? 'floating' : ''
        const openClass = scroll.isScrollingUp ? 'open' : ''
        const doneClass = scroll.isBottomReached ? 'done' : ''
        const isReading = scroll.isReadingContent

        const draftStyle = {
            backgroundImage: 'url(' + Background + ')',
        }


        // Instead of defining it's own header, footer and other common layouts, it should extend from PageLayout
        // It's bad, very bad, you shouldn't create new layout with this format
        //
        // I'm forced to write this down - Someone
        return (
            <div className={`app-root guideline ${floatingClass} ${isReading ? 'is-reading': ''}`}>
                <div id="page-content">
                    <Header openClass={openClass} activeSection={SECTIONS.HEALTH_PROFESSIONALS.key} />
                        <main>
                            <Sidebar doneClass={doneClass}>
                                <h5>Within this Page</h5>
                                <IndexList className={'page-index'} />
                                <h5>Tools</h5>
                                <ToolsItems
                                    onShareClick={() => togglePopup(POPUP_ID.SHARE)}
                                    onHealthPathwaysClick={() => togglePopup(POPUP_ID.HEALTH_PATHWAYS)}
                                    onFormsClick={() => togglePopup(POPUP_ID.FORMS)}
                                    onPatientInformationClick={() => togglePopup(POPUP_ID.PATIENT_INFORMATION)}
                                    onDrugDosageClick={() => togglePopup(POPUP_ID.DRUG_DOSAGE)}
                                    onCalculatorsClick={() => togglePopup(POPUP_ID.CALCULATORS)}
                                    onBookmarkClick={(guidelineContext) => this.onBookmarkClick(guidelineContext)}
                                    isBookmarked={isBookmarked}
                                />
                            </Sidebar>
                            <Content preview={preview} draftStyle={draftStyle} location={location} activeSection={SECTIONS.HEALTH_PROFESSIONALS.key} />
                        </main>
                    <Footer activeSection={SECTIONS.HEALTH_PROFESSIONALS.key} hideSocials sitemap={sitemap} />
                </div>
                <div className="tools-btn desktop" onClick={scroll.scrollToTop}>
                    <i className="far fa-arrow-up icon" />
                </div>
                <div className="tools-btn mobile d-flex d-lg-none" onClick={() => togglePopup(POPUP_ID.TOOLS)}>
                    <i className="far fa-plus icon" />
                    <span>Tools</span>
                </div>
                <PopupManager />
                <ReactTooltip disable={isMobile} />
                <Overlay />
            </div>
        )
    }
}

export default withPopupContext(withScrollContext(GuidelineLayout))
