import React from 'react'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { withGuidelineContext } from '../../contexts/GuidelineContext'
import LinkItem from '../common/LinkItem'

export default withGuidelineContext((props) => {
    const guideline = props.guidelineContext.info;

    if(isEmpty(guideline.healthPathways)) {
        return null;
    }

    return (
        <div className="col">
            {map(guideline.healthPathways, (pathway, i) => (
                <div key={pathway.text + i} className="row">
                    <LinkItem information={pathway} />
                </div>
            ))}
        </div>
    )
})