import React from 'react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { withGuidelineContext } from '../../contexts/GuidelineContext';
import InformationItem from '../common/InformationItem'

export default withGuidelineContext((props) => {
    const guideline = props.guidelineContext.info;

    if(isEmpty(guideline.informationForFamilies)) {
        return null;
    }

    return (
        <div className="col">
            {map(guideline.informationForFamilies, (information, i) => (
                <div key={information.text + i} className="row">
                    <InformationItem key={i} information={information} />
                </div>
            ))}
        </div>
    )
})