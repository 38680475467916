import React from 'react'
import { GuidelineCategoryList, SearchBar } from '../common'
import classNames from 'classnames'
import FeatureListWidget from './FeatureListWidget'

export default (props) => {
    const { className, activeSection } = props
    const { title, description, bgImage, showCategories, featureList } = props.data
    const backgroundGradient = 'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.5) 100%)'
    
    return (
        <React.Fragment>
            <div className={classNames('search-panel', className, { 'has-categories': showCategories })}>
                <div className={'box-with-categories'}>
                    <div className={'searchbox-wrapper'} style={{backgroundImage: `${backgroundGradient}, url(https:${bgImage.file.url})`}} role="img" aria-label={bgImage.title}>
                        <img src={bgImage.file.url} className={'searchbox-heading'} alt={bgImage.title} title="Search Background" />
                        <div className="info">
                            <h2>{title}</h2>
                            <p>{ description ? description.description : null }</p>
                            <SearchBar id={'searchbar-panel-widget'} activeSection={activeSection} withOverlay={false} alwaysActive={true} />
                        </div>

                    
                    </div>
                    {showCategories && <GuidelineCategoryList />}
                </div>
                {featureList && <FeatureListWidget featureList={featureList} />}
            </div>
        </React.Fragment>
        
    )
}