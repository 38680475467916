import React from 'react'
import map from 'lodash/map'
import get from 'lodash/get'
import { buildURL } from '../../utils/url'
import { Link } from 'gatsby'
import EventTracker from './../../helpers/EventTracker'

export default class FeatureListWidget extends React.Component {
    /*
     * Show an image block, when mouse hover show a shade and title of the block
     */
    getStoryFeature({ isInternal, key, link, linkText, backgroundImage, backgroundImageTitle }) {
        return (
            <li key={key}>
                {isInternal ? (
                    <Link to={link} className="feature hero-feature" onClick={() => EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.HERO_WIDGET, EventTracker.EVENT_TYPES.CLICK, 'STORY')} role="img" aria-label={backgroundImageTitle} style={{ backgroundImage: `url("${backgroundImage}")` }}>
                        <div className="overlay" />
                        <span>{linkText}</span>
                    </Link>
                ) : (
                    <a href={link} className="feature hero-feature" onClick={() => EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.HERO_WIDGET, EventTracker.EVENT_TYPES.CLICK, 'STORY')} role="img" aria-label={backgroundImageTitle} style={{ backgroundImage: `url("${backgroundImage}")` }}>
                        <div className="overlay" />
                        <span>{linkText}</span>
                    </a>
                )}
            </li>
        )
    }

    /*
     * Show an link block, with title and an optional icon
     */
    getLinkFeature({ isInternal, key, link, linkText, icon }) {
        return (
            <li key={key}>
                {isInternal ? (
                    <Link onClick={() => EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.HERO_WIDGET, EventTracker.EVENT_TYPES.CLICK, 'LINK')} to={link} className="feature hero-feature-link">
                        <span>{linkText}</span>
                        {icon && <i className={`fal ${icon} icon`} />}
                    </Link>
                ) : (
                    <a onClick={() => EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.HERO_WIDGET, EventTracker.EVENT_TYPES.CLICK, 'LINK')} href={link} className="feature hero-feature-link">
                        <span>{linkText}</span>
                        {icon && <i className={`fal ${icon} icon`} />}
                    </a>
                )}
            </li>
        )
    }

    getStoryBlockFeatureLink(feature) {
        return feature.story || feature.internalHyperlink || feature.externalHyperlink
    }

    render() {
        const { featureList } = this.props
        return (
            <div className="features hero-features">
                <ul>
                    {map(featureList, (feature) => {
                        let params = {}
                        if (feature.__typename === 'ContentfulWidgetStoryBlock' || feature.__typename === 'ContentfulWidgetStoryBlockPtp') {
                            const link = this.getStoryBlockFeatureLink(feature)
                            params = {
                                isInternal: true,
                                key: feature.slug,
                                link: buildURL(link),
                                linkText: feature.title,
                                backgroundImage: get(feature, 'backgroundImage.file.url'),
                                backgroundImageTitle: get(feature, 'backgroundImage.title'),
                                icon: 'fa-heart',
                            }
                        } else {
                            // Check if featureLink is null, an array, or a single element
                            const featureLinkArray = Array.isArray(feature.featureLink) ? feature.featureLink : feature.featureLink ? [feature.featureLink] : []

                            if (featureLinkArray.length > 0 && (featureLinkArray[0]?.__typename !== 'ContentfulWidgetLink' || featureLinkArray[0]?.__typename !== 'ContentfulWidgetLinkPtp')) {
                                params = {
                                    isInternal: true,
                                    key: feature.slug,
                                    link: buildURL(featureLinkArray[0]),
                                    linkText: feature.title,
                                    backgroundImage: get(feature, 'backgroundImage.file.url'),
                                    backgroundImageTitle: get(feature, 'backgroundImage.title'),
                                    icon: feature.icon || null,
                                }
                            } else {
                                params = {
                                    isInternal: false,
                                    key: feature.slug,
                                    link: featureLinkArray[0].url,
                                    linkText: feature.title,
                                    backgroundImage: get(feature, 'backgroundImage.file.url'),
                                    icon: feature.icon || null,
                                }
                            }
                        }

                        return this.getLinkFeature(params)
                    })}
                </ul>
            </div>
        )
    }
}
