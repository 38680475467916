import React from 'react'
import get from 'lodash/get'

export default (props) => {
    const { information } = props

    const text = get(information, 'title')
    const url = get(information, 'media.fields.shortURL')  || get(information, 'media.file.url')
    const size = get(information, 'media.file.details.size')
    const roundedSize = Math.ceil(size/1024);

    return (
        <div className="link file">
            <div className="icon-box">
                <i className="far fa-file-pdf icon" />
            </div>
            <div className="content-box">
                <a target="_blank" rel="noopener noreferrer" href={url}>{text}</a>
                {size && <span className="file-size">{roundedSize} KB</span>}
            </div>
        </div>
    )
}