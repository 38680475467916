import React, { useEffect, useState, memo, useCallback } from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import lambdaAPI from '../../lambdaAPI';
import Loader from './Loader';
import MasonryWidget from './MasonryWidget';

const TwitterWidget = props => {
  const [tweetIDs, setTweetIDs] = useState([]);
  const [accountId, setAccountId] = useState(null);
  const [paginationToken, setPaginationToken] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    lambdaAPI.getTwitterFeed(props.userSection, null, 5, null).then(data => {
      if (data.statusCode === 200) {
        setAccountId(data.body.id);
        const IDs = data.body.data;
        setTweetIDs(IDs);
        setPaginationToken(data.body.meta.next_token);
      }
    }).catch(error => {

    });
  }, [props.userSection])

  const loadMore = useCallback(() => {
    setLoading(true);
    lambdaAPI.getTwitterFeed(props.userSection, accountId, 5, paginationToken).then(data => {
      if (data.statusCode === 200) {
        setAccountId(data.body.id);
        const IDs = data.body.data;
        setTweetIDs(prev => [...prev, ...IDs]);
        setPaginationToken(data.body.meta.next_token);
      }
    }).catch(error => {

    }).finally(() => setLoading(false));
  })

  return (
    tweetIDs.length ?
      <div className='twitter'>
        <h2>Read latest posts - Twitter</h2>
        <div className='twitter-posts'>
          <MasonryWidget data={tweetIDs} element={(value) => <div className='twitter-post'><TwitterTweetEmbed tweetId={value.id} placeholder='Loading' /></div>} />
        </div>
        <Loader title="Load more tweets" loading={loading} loadMore={loadMore} />
      </div>
    : <></>
  )
}

export default memo(TwitterWidget);
