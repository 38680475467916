import React, { PureComponent, Fragment } from 'react'
import Popup from './../common/Popup'
import Tools from './Tools'
import { withPopupContext, withGuidelineContext } from '../../contexts'
import IndexList from './GuidelineIndexList'
import HealthPathways from './HealthPathways'
import Forms from './Forms'
import PatientInformation from './PatientInformation'
import Disclaimer from './Disclaimer'
import DrugDosage from './DrugDosage'
import Calculators from './Calculators'
import SharePopup from './SharePopup'
import  MobileHeader  from '../common/MobileHeader'

class PopupManager extends PureComponent {
    toggleSelectIndexPopup = () => {
        const { togglePopup, POPUP_ID } = this.props.popupContext
        togglePopup(POPUP_ID.INDEX)
    }

    toggleToolsModal = () => {
        const { togglePopup, POPUP_ID } = this.props.popupContext
        togglePopup(POPUP_ID.TOOLS)
    }

    toggleHealthPathwaysModal = () => {
        const { togglePopup, POPUP_ID } = this.props.popupContext
        togglePopup(POPUP_ID.HEALTH_PATHWAYS)
    }

    toggleFormsModal = () => {
        const { togglePopup, POPUP_ID } = this.props.popupContext
        togglePopup(POPUP_ID.FORMS)
    }

    togglePatientInformationPopup = () => {
        const { togglePopup, POPUP_ID } = this.props.popupContext
        togglePopup(POPUP_ID.PATIENT_INFORMATION)
    }

    toggleDisclaimer = () => {
        const { togglePopup, POPUP_ID } = this.props.popupContext
        togglePopup(POPUP_ID.DISCLAIMER)
    }

    toggleDrugDosage = () => {
        const { togglePopup, POPUP_ID } = this.props.popupContext
        togglePopup(POPUP_ID.DRUG_DOSAGE)
    }

    toggleSharePopup = () => {
        const { togglePopup, POPUP_ID } = this.props.popupContext
        togglePopup(POPUP_ID.SHARE)
    }

    toggleCalculator = () => {
        const { togglePopup, POPUP_ID } = this.props.popupContext
        togglePopup(POPUP_ID.CALCULATORS)
    }

    shareContent = () => {
        this.props.popupContext.closePopup()
        // trigger native share if available
        if (navigator.share !== undefined) {
            navigator
                .share({
                    title: document.title,
                    text: document.title,
                    url: window.location.href,
                })
                .catch(e => {
                    if (e.code !== 20) {
                        // 20 is for native share cancelation https://wicg.github.io/web-share/#share-method, else we are showing the html share menu as a fallback
                        this.toggleSharePopup()
                    }
                })
        } else {
            this.toggleSharePopup()
        }
    }

    render = () => {
        const popupContext = this.props.popupContext
        const { popupShowing, closePopup } = popupContext
        const propParent = this.props.propParent
        return (
            <Fragment>
                {popupShowing === popupContext.POPUP_ID.INDEX && (
                    <Popup title={'Within this Page'} className="no-padding">
                       {
                       !propParent ? 
                       <IndexList className={'m-0 p-0'} /> :
                        <MobileHeader propParent={propParent} className={'m-0 p-0'} trim={true}/>
                        }
                    </Popup>
                )}
                {popupShowing === popupContext.POPUP_ID.TOOLS && (
                    <Tools
                        {...this.props}
                        closeModal={closePopup}
                        onShareClick={this.shareContent}
                        onHealthPathwaysClick={this.toggleHealthPathwaysModal}
                        onFormsClick={this.toggleFormsModal}
                        onPatientInformationClick={this.togglePatientInformationPopup}
                        onDrugDosageClick={this.toggleDrugDosage}
                        onCalculatorClick={this.toggleCalculator}
                    />
                )}
                {popupShowing === popupContext.POPUP_ID.HEALTH_PATHWAYS && (
                    <Popup title={'Health Pathways'} className="secondary">
                        <HealthPathways />
                    </Popup>
                )}
                {popupShowing === popupContext.POPUP_ID.FORMS && (
                    <Popup title={'Forms'} className="secondary">
                        <Forms />
                    </Popup>
                )}
                {popupShowing === popupContext.POPUP_ID.PATIENT_INFORMATION && (
                    <Popup title={'Information for Families'} className="secondary">
                        <PatientInformation />
                    </Popup>
                )}
                {popupShowing === popupContext.POPUP_ID.DISCLAIMER && (
                    <Popup title={'Disclaimer'} className="primary">
                        <Disclaimer />
                    </Popup>
                )}
                {popupShowing === popupContext.POPUP_ID.DRUG_DOSAGE && (
                    <Popup title={'Drug Dosage'} className="secondary">
                        <DrugDosage />
                    </Popup>
                )}
                {popupShowing === popupContext.POPUP_ID.SHARE && (
                    <Popup title={'Share'} className="secondary">
                        <SharePopup />
                    </Popup>
                )}
                {popupShowing === popupContext.POPUP_ID.CALCULATORS && (
                    <Popup title={'Calculators'} className="secondary">
                        <Calculators />
                    </Popup>
                )}
            </Fragment>
        )
    }
}

export default withPopupContext(withGuidelineContext(PopupManager))
