import React from 'react'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { Link } from 'react-scroll'
import { withGuidelineContext, withScrollContext, withPopupContext } from '../../contexts'


const GuidelineIndexList = (props) => {
    const {
        className,
        trim,
    } = props

    const { info: guideline, indexes } = props.guidelineContext
    const { offset } = props.scroll

    return (
        <ul className={className}>
            {
                map(indexes, (index, i) => (
                    <li key={index.to} className="item" title={index.text}>
                        <Link activeClass={'active'} to={index.to} spy={true} smooth={true} offset={offset} onClick={props.popupContext.closePopup} >
                            { trim && index.text.length > 60 ? `${index.text.slice(0, 57)}...` : index.text }
                        </Link>
                    </li>
                ))
            }
            {
                !isEmpty(get(guideline, 'references.childContentfulRichText.html')) && (
                    <li className="item" title="References">
                        <Link activeClass={'active'} to={'references'} spy={true} smooth={true} offset={offset} onClick={props.popupContext.closePopup} >
                            References
                        </Link>
                    </li>
                )
            }
            {
                guideline.relatedInformation && (
                    <li className="item" title="Related Information">
                        <Link activeClass={'active'} to={'related-information'} spy={true} smooth={true} offset={offset} onClick={props.popupContext.closePopup} >
                            Related Information
                        </Link>
                    </li>
                )
            }
            {
                !isEmpty(guideline.authors) && (
                    <li className="item" title="Document Control">
                        <Link activeClass={'active'} to={'document-control'} spy={true} smooth={true} offset={offset} onClick={props.popupContext.closePopup} >
                            Document Control
                        </Link>
                    </li>
                )
            }


        </ul>
    )
}

export default withPopupContext(withScrollContext(withGuidelineContext(GuidelineIndexList)));