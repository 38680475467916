import React from 'react'
import get from 'lodash/get'
import { withGuidelineContext } from '../../contexts/GuidelineContext'
import Category from './Category'

export default withGuidelineContext((props) => {
    const {doneClass, children} = props
    const category = get(props, 'guidelineContext.info.categories[0]')

    return (
        <aside className={`sidebar ${doneClass}`}>
            <div className="sidebar-box">
                {children}
                <Category {...props} category={category}/>
            </div>
        </aside>
    )
});