import React, { Component } from 'react'
import isUndefined from 'lodash/isUndefined'
import get from 'lodash/get'
import { withScrollContext } from '../../contexts/ScrollContext'
import ToolsItems from '../common/ToolsItems'
import PropTypes from 'prop-types'

class Tools extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            isBookmarked: this.isBookmarked(props),
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.isLoading !== nextState.isLoading || this.state.isBookmarked !== nextState.isBookmarked
    }

    componentDidMount() {
        document.body.classList.add('no-scroll')
        setTimeout(() => {
            this.setState({
                isLoading: false,
            })
        }, 200)
    }

    isBookmarked = (props) => {
        if (typeof window !== 'undefined') {
            const bookmarks = JSON.parse(localStorage.getItem('bookmarks')) || [];
            const { guidelineContext } = props || this.props;
            const slug = get(guidelineContext, 'info.slug');
            const value = bookmarks.some(bookmark => slug === bookmark.slug);
            return value
        }
        return false;
    }

    onBookmarkClick = (guidelineContext) => {
        const isBookmarked = this.isBookmarked();
        const bookmarks = JSON.parse(localStorage.getItem('bookmarks')) || [];
        const slug = get(guidelineContext, 'info.slug');
        if (isBookmarked) {
            const bookmarkIndex = bookmarks.findIndex(bookmark => slug === bookmark.slug);
            bookmarks.splice(bookmarkIndex, 1);
            localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
        } else {
            const guideline = {
                title: get(guidelineContext, 'info.title'),
                slug: get(guidelineContext, 'info.slug'),
                categories: get(guidelineContext, 'info.categories'),
                guidelineType: get(guidelineContext, 'info.guidelineType'),
                isBookmarked: true,
                searchRedirectUrl: get(guidelineContext, 'info.searchRedirectUrl'),
                serviceProvider: get(guidelineContext, 'info.serviceProvider')
            }
            bookmarks.push(guideline);
            localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
        }
        this.setState({
            isBookmarked: !this.state.isBookmarked
        });
    }

    closeModal = () => {
        !isUndefined(document) && document.body.classList.remove('no-scroll')
        this.setState({
            isLoading: true,
        })

        setTimeout(() => {
            this.props.closeModal()
        }, 200)
    }

    render() {
        const { isLoading, isBookmarked } = this.state
        const { onHealthPathwaysClick, onPatientInformationClick, onDrugDosageClick, onShareClick, onCalculatorClick, onFormsClick } = this.props

        return (
            <div id="tools-modal" className={`modal ${isLoading ? '' : 'loaded'}`}>
                <div className={`close ${isLoading ? '' : 'active'}`} onClick={this.closeModal}>
                    <i className="far fa-plus icon" />
                </div>

                <ToolsItems
                    className={`${isLoading ? '' : 'active'}`}
                    onShareClick={onShareClick}
                    onHealthPathwaysClick={onHealthPathwaysClick}
                    onFormsClick={onFormsClick}
                    onPatientInformationClick={onPatientInformationClick}
                    onDrugDosageClick={onDrugDosageClick}
                    onCalculatorsClick={onCalculatorClick}
                    isBookmarked={isBookmarked}
                    onBookmarkClick={(guidelineContext) => this.onBookmarkClick(guidelineContext)}
                />
            </div>
        )
    }
}

Tools.propTypes = {
    onHealthPathwaysClick: PropTypes.func.isRequired,
    onPatientInformationClick: PropTypes.func.isRequired,
    onDrugDosageClick: PropTypes.func.isRequired,
    onShareClick: PropTypes.func.isRequired,
    onCalculatorClick: PropTypes.func.isRequired,
}

export default withScrollContext(Tools)
