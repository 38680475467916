import React, { Component } from "react"
import { Link } from 'gatsby'
import logo from "../../assets/images/logo-starship.png"
import { withScrollContext, withPopupContext } from "../../contexts"
import SearchBar from '../common/search/SearchBar'
import { SECTIONS } from './../../utils/url'

class MobileFloatingBar extends Component {
    state = {
        isSearchActive: false,
    }

    componentDidMount() {
        const floatingBar = document.getElementById('mobile-floating-bar')
        const self = this
        const observer = new MutationObserver(function (event) {
            if(!floatingBar.classList.contains('open')) {
                self.closeFloatingSearchbar()
            }
        })

        observer.observe(floatingBar, {
            attributes: true,
            attributeFilter: ['class'],
            childList: false,
            characterData: false
        })
    }

    openSearch = () => this.setState({ isSearchActive: true })
    closeSearch = () => this.setState({ isSearchActive: false })
    closeFloatingSearchbar = () => this.floatingSearchbar.closeSearchBar()

    render() {
        const { isFixedIndex, isHeaderOpen, children, popupContext, activeSection, noPopup } = this.props
        const { isSearchActive } = this.state

        return (
            <div id="mobile-floating-bar" className={`${isFixedIndex ? 'floating' : ''} ${isHeaderOpen ? 'open' : ''}`}>
                <div className="app-header">
                    <div className="wrapper">
                        <Link id="logo" to="/"><img src={logo} alt="Starship Logo" title="Starship Logo"/></Link>
                        <div id="floating-searchbar-wrapper" className={isSearchActive ? 'active' : ''} >
                            <SearchBar id={'floating-searchbar'} activeSection={activeSection} ref={el => this.floatingSearchbar = el}  withOverlay={false} openSearch={() => this.openSearch()} closeSearch={() => this.closeSearch()} />
                            <div className="overlay" />
                        </div>
                        <a href="https://starship-foundation-donate.raisely.com/" rel='noopener noreferrer' target='_blank' className="donate-btn">
                            <i className="far fa-heart icon" />
                            <span>Donate</span>
                        </a>
                        { SECTIONS.HEALTH_PROFESSIONALS.key === activeSection && <Link to="/guidelines/my-shortlist" className="shortlist-btn">
                            <i className="fas fa-star icon" />
                        </Link> }
                        <Link to="/contact-us" className={SECTIONS.HEALTH_PROFESSIONALS.key === activeSection ? `contact-btn contact-btn-shift` : `contact-btn`}>
                            <i className="fas fa-info icon" />
                        </Link>
                    </div>
                </div>
                <div id="innerIndex">
                    <div className="inner">
                        {children}
                    </div>
                    {!noPopup && <div className="expand" onClick={() => popupContext?.togglePopup(popupContext.POPUP_ID.INDEX)}>
                        <i className="far fa-expand-alt icon" />
                    </div>}
                    
                </div>
            </div>
        )
    }
}

export default withPopupContext(withScrollContext(MobileFloatingBar))