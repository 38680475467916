import React from 'react'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { Link } from 'react-scroll'
import { withGuidelineContext, withScrollContext, withPopupContext } from '../../contexts'

export default withPopupContext(
    withScrollContext(
        withGuidelineContext(props => {
            const dosageTables = props.guidelineContext.dosageTables
            const externalTables = props.guidelineContext.info.externalTables
            const { offset } = props.scroll

            if (isEmpty(dosageTables) && isEmpty(externalTables)) {
                return null
            }

            return (
                <div className="col">
                    {!isEmpty(dosageTables) &&
                        map(dosageTables, (table, i) => (
                            <div key={table.text + i} className="row">
                                <Link activeClass={'active'} to={table.to} spy={true} smooth={true} offset={offset} onClick={props.popupContext.closePopup} className="link file">
                                    <span className="icon-box">
                                        <i className="far fa-vial icon" />
                                    </span>
                                    <span className="content-box">{table.text}</span>
                                </Link>
                            </div>
                        ))}
                    {!isEmpty(externalTables) &&
                        map(externalTables, (table, i) => (
                            <div key={table.text || table.title + i} className="row">
                                <a href={table.url || '/guidelines/'+table.slug} target="_blank" rel="noopener noreferrer" onClick={props.popupContext.closePopup} className="link file">
                                    <span className="icon-box">
                                        <i className="far fa-link icon" />
                                    </span>
                                    <span className="content-box">{table.text || table.title}</span>
                                </a>
                            </div>
                        ))}
                </div>
            )
        })
    )
)
