import React from 'react'
import { Link } from 'gatsby'
import get from 'lodash/get'
import { buildURL, CONTENTFUL_GRAPHQL_TYPES } from '../../utils/url'

export default (props) => {
    const { information } = props

    const title = get(information, 'title')
    const url = buildURL(information, CONTENTFUL_GRAPHQL_TYPES.GUIDELINE);

    return (
        <div className="link">
            <div className="icon-box">
                <i className="far fa-notes-medical icon" />
            </div>
            <div className="content-box">
                <a target="_blank" rel="noopener noreferrer" href={url}>{title}</a>
            </div>
        </div>
    )
}