import React, { Component } from 'react'
import { Link } from 'gatsby'
import get from 'lodash/get'
import { buildURL } from '../../utils/url'
import EventTracker from './../../helpers/EventTracker'

export default class StoryBlockWidget extends Component {
    render() {
        const { className } = this.props
        const { title, story, summary, backgroundImage, externalHyperlink, internalHyperlink } = this.props.data
        return (
            <div className={`block story-block ${className ? className : ''}`}>
                <div className="block-heading" role="img" aria-label={backgroundImage.title} style={{ backgroundImage: `url(${backgroundImage.file.url})` }} />
                <div className="block-info">
                    <div className="block-body">
                        {/* TODO: Fix how this link is build */}
                        <h4>
                            <Link to={story ? buildURL(story) : internalHyperlink ? buildURL(internalHyperlink) : externalHyperlink} onClick={() => EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.STORY_BLOCK, EventTracker.EVENT_TYPES.CLICK, 'TITLE')}>
                                {' '}
                                {title}
                            </Link>
                        </h4>
                        <p>{get(summary, 'summary')}</p>
                    </div>
                    <div className="block-links">{this.donate()}</div>
                </div>
            </div>
        )
    }

    donate = () => {
        let { pageDonation, preview } = this.props
        if (!pageDonation) pageDonation = this.props.data.story ? this.props.data.story.pageDonation : null
        if (preview) pageDonation = pageDonation.fields
        const { donateAmount, recurring, donateReason, externalHyperlink, internalHyperlink, textButton } = this.props.data
        const internalUrl = buildURL(internalHyperlink)

        if (externalHyperlink) {
            return (
                <a className="buttonLink" href={externalHyperlink} target="_blank" onClick={() => EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.STORY_BLOCK, EventTracker.EVENT_TYPES.CLICK, 'DONATE_BUTTON')}>
                    {!textButton && <i className="fa fa-heart icon" />}
                    {textButton ? textButton : `Donate${donateAmount ? ` $${donateAmount}` : ''}`}
                </a>
            )
        }

        if (internalHyperlink) {
            return (
                <Link className="buttonLink" to={internalUrl} onClick={() => EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.STORY_BLOCK, EventTracker.EVENT_TYPES.CLICK, 'DONATE_BUTTON')}>
                    {!textButton && <i className="fa fa-heart icon" />}
                    {textButton ? textButton : `Donate${donateAmount ? ` $${donateAmount}` : ''}`}
                </Link>
            )
        }

        if (pageDonation && pageDonation.slug) {
            const data = {
                donateAmount: donateAmount,
                recurring: recurring,
                donateReason: donateReason,
                pageDonation: pageDonation,
            }
            const url = `/donation-page/${pageDonation.slug}`
            return (
                <Link className="buttonLink" to={url} replace state={data} onClick={() => EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.STORY_BLOCK, EventTracker.EVENT_TYPES.CLICK, 'DONATE_BUTTON')}>
                    {!textButton && <i className="fa fa-heart icon" />}
                    {textButton ? textButton : `Donate${donateAmount ? ` $${donateAmount}` : ''}`}
                </Link>
            )
        } else {
            let paramStrings = [`recurring=${recurring}`]
            if (donateAmount) {
                paramStrings.push(`amount=${donateAmount}`)
            }

            if (donateReason) {
                paramStrings.push(`reason=${donateReason}`)
            }
            return (
                <Link className="buttonLink" to={`/foundation/donation-form?${paramStrings.join('&')}`} onClick={() => EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.STORY_BLOCK, EventTracker.EVENT_TYPES.CLICK, 'DONATE_BUTTON')}>
                    {!textButton && <i className="fa fa-heart icon" />}
                    {textButton ? textButton : `Donate${donateAmount ? ` $${donateAmount}` : ''}`}
                </Link>
            )
        }
    }
}
