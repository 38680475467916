import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import richTextReactRendering from '../../utils/richTextReactRendering'

export default (props) => {
    const { content } = props.data
    const bodyContent = content.childContentfulRichText.internal.content
    const body = typeof(bodyContent) === 'object' ? bodyContent : JSON.parse(bodyContent)
    return <div className="rich-text-widget">{body && documentToReactComponents(body, richTextReactRendering)}</div>
}
