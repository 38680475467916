import React, { Component } from 'react'

import '../../assets/styles/widgets/_logoWidget.scss'

export default class LogoWidget extends Component {
    render() {
        const { className } = this.props
        const imgfile  = "https:" + this.props.data.image.file.url;

        return (
            <div>
              <img className={`logo-widget`} src={imgfile} />
            </div>
        )
    }
}