import React from 'react'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { withGuidelineContext, withPopupContext } from '../../contexts'
import { buildURL } from '../../utils/url';

const Calculator = props => {
    const calculators = props.guidelineContext.info.calculators

    if (isEmpty(calculators)) {
        return null
    }

    return (
        <div className="col">
            {!isEmpty(calculators) &&
            map(calculators, (calculator, i) => {
                const url = buildURL(calculator);

                return (
                    <div key={calculator.name + i} className="row">
                        <a href={url} onClick={props.popupContext.closePopup} className="link file" target="_blank" rel="noopener noreferrer">
                                <span className="icon-box">
                                    <i className="far fa-calculator-alt icon" />
                                </span>
                            <span className="content-box">{calculator.name}</span>
                        </a>
                    </div>
                )
            })}
        </div>
    )
}
export default withGuidelineContext(withPopupContext(Calculator))
