import React from 'react'
import Panel from '../../common/Panel'

export default (props) => {
    const { title, id, content, className } = props;

    if(!content) {
        return null
    }

    return (
        <Panel title={title} id={id} className={className}>
            <div dangerouslySetInnerHTML={{
                __html: content.childContentfulRichText.html,
            }} />
        </Panel>
    )
}