import React from 'react'
import LinkItem from './LinkItem'
import MediaItem from './MediaItem'
import GuidelineItem from './GuidelineItem'

export default (props) => {
    const { information } = props

    if(!!information.media) {
        return <MediaItem information={information} />
    };

    if(!!information.slug) {
        return <GuidelineItem information={information} />
    };

    return <LinkItem information={information} />
}