import React, { useState, memo, useCallback, useEffect } from 'react'
import Masonry from '../common/Masonry'

const MasonryWidget = (props) => {

  const settingColumns = useCallback(() => {
    if(window.innerWidth >= 1300) return 3
    if(window.innerWidth >= 800) return 2
    if(window.innerWidth >= 500) return 1
    return 1
  }, [])

  const [column, setColumn] = useState(() => settingColumns())

  useEffect(() => {
    window.addEventListener('resize', () => setColumn(() => settingColumns()))

    return window.removeEventListener('resize', () => setColumn(() => settingColumns()))
  }, [setColumn, settingColumns])

  return (
    <Masonry dataArray={props.data} columnCount={column} ChildsElement={memo(({ value }) => props.element(value))} />
  )
}

export default memo(MasonryWidget);