import React from 'react'
import PagesGroup from './PagesGroup'
import SearchPanelWidget from './SearchPanelWidget'
import StoryBlockWidget from './StoryBlockWidget'
import LinkBlockWidget from './LinkBlockWidget'
import HeroWidget from './HeroWidget'
import Links from './Links'
import RichTextWidget from './RichTextWidget'
import ImpactWidget from './ImpactWidget'
import SocialFeed from './SocialFeed'

export default (props) => {
    const { widget, activeSection, fullWidth } = props
    const type = widget?.__typename
    const _window = typeof window !== 'undefined' && window
    const isIE11 = !!_window.MSInputMethodContext && !!document.documentMode

    switch (type) {
        case 'ContentfulPageGroup':
        case 'ContentfulPageGroupPtp':
            return <PagesGroup className="col" widget={widget} />
        case 'ContentfulWidgetSearchPanel':
        case 'ContentfulWidgetSearchPanelPtp':
            return <SearchPanelWidget className="col" data={widget} activeSection={activeSection} />
        case 'ContentfulWidgetStoryBlock':
        case 'ContentfulWidgetStoryBlockPtp':
            return <StoryBlockWidget className="col" data={widget} />
        case 'ContentfulWidgetHero':
        case 'ContentfulWidgetHeroPtp':
            return <HeroWidget className="col" data={widget} activeSection={activeSection} fullWidth={fullWidth} isIE11={isIE11} />
        case 'ContentfulWidgetLinks':
        case 'ContentfulWidgetLinksPtp':
            return <Links data={widget} />
        case 'ContentfulWidgetRichText':
        case 'ContentfulWidgetRichTextPtp':
            return <RichTextWidget className="col" data={widget} />
        case 'ContentfulWidgetLinkBlock':
        case 'ContentfulWidgetLinkBlockPtp':
            return <LinkBlockWidget className="col" data={widget} />
        case 'ContentfulWidgetImpact':
        case 'ContentfulWidgetImpactPtp':
            return <ImpactWidget className="col" data={widget} />
        case 'ContentfulWidgetSocialFeed':
        case 'ContentfulWidgetSocialFeedPtp':
            return <SocialFeed className="col" data={widget} />
        default:
            return <div>Oops! No render for {type}</div>
    }
}
