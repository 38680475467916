import React, { useEffect, useState, memo, useCallback } from 'react';
import { InstagramEmbed } from 'react-social-media-embed';
import Loader from './Loader';
import lambdaAPI from '../../lambdaAPI';
import MasonryWidget from './MasonryWidget';

const InstagramWidget = props => {
  const [posts, setPosts] = useState([]);
  const [limit, setLimit] = useState(4);
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    lambdaAPI.getmetaPosts(props.userSection, 'instagram', limit).then(data => {
      if (data.statusCode === 200) {
        setPosts(data.body.data);
      }
    }).catch((error) => {

    })
    setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
  }, [props.userSection])

  const loadMore = useCallback(() => {
    setLoading(true);
    const l = limit + 4;
    setLimit(l);
    lambdaAPI.getmetaPosts(props.userSection, 'instagram', l).then(data => {
      if (data.statusCode === 200) {
        setPosts(data.body.data);
      }
    }).catch(error => {
      setLimit(limit - 4);
    }).finally(() => {
      setLoading(false);
    });
  })

  return (
    posts.length ?
      <div className='instagram'>
        <h2>Read latest posts - Instagram</h2>
        <div className='instagram-posts'>
          <MasonryWidget data={posts} element={(value) => <div className='instagram-post'><InstagramEmbed url={value.permalink} width={isMobile ? null : 350} /></div>} />
        </div>
        <Loader title="Load more posts" loading={loading} loadMore={loadMore} />
      </div>
    : <></>
  )
}

export default memo(InstagramWidget);
