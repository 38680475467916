import React from 'react'
import { goto } from '../../utils/url'
import EventTracker from './../../helpers/EventTracker'

export default (props) => {
    const { className, data } = props
    const { title, summary, backgroundImage, buttonText, buttonLink, icon } = data
    const buttonLinkUrl = Array.isArray(buttonLink) ? buttonLink[0] : buttonLink
    return (
        <div className={`block story-block ${className ? className : ''}`}>
            <div className="block-heading" style={{ backgroundImage: `url(${backgroundImage?.file?.url})` }} />
            <div className="block-info">
                <div className="block-body">
                    <h4>{title}</h4>
                    <p>{summary.summary}</p>
                </div>
                <div className="block-links">
                    <button
                        onClick={() => {
                            EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.LINK_BLOCK, EventTracker.EVENT_TYPES.CLICK)
                            goto(buttonLinkUrl)
                        }}
                    >
                        {icon && <i className={`fa ${icon} icon`} />} {buttonText || 'View More'}
                    </button>
                </div>
            </div>
        </div>
    )
}
