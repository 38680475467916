
import React from "react"

const Category = (props) => {

    if(!props.category) {
        return null
    }

    const { colour, name } = props.category

    const bgColour = props.bgColour || colour

    return (
        <div className="service-provider" style={{backgroundColor: bgColour}}>
            <div className="inner">
                {name}
            </div>
        </div>
    )
}

export default Category