import React from "react";
import FacebookWidget from "./FacebookWidget";
import InstagramWidget from "./InstagramWidget";
import TwitterWidget from "./TwitterWidget";

const SocialFeed = props => {
  const { data } = props;
  const feeds = data.feed;
  const userSection = data.userSection;
  return (
    <>
      {feeds.includes('Facebook') ? <FacebookWidget userSection={userSection} /> : null}
      {feeds.includes('Twitter') ? <TwitterWidget userSection={userSection} /> : null}
      {feeds.includes('Instagram')? <InstagramWidget userSection={userSection} /> : null}
    </>
  )
}

export default SocialFeed;
