import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import sortBy from 'lodash/sortBy'
import map from 'lodash/map'
import has from 'lodash/has'
import EventTracker from './../../helpers/EventTracker'

import { buildURL, CONTENTFUL_GRAPHQL_TYPES, pagesGroupChildTypes } from '../../utils/url'

export default class PagesGroup extends PureComponent {
    constructor(props) {
        super(props);
        
        const { 
            title, 
            slug,
            headerImage,
            image, 
            imagePosition, 
            page__generic: genericPages,
            page__widgets: widgetsPages,
            pages_group: groupPages,
            userSection
        } = props.widget
        
        const pages = [
            ...(genericPages || []),
            ...(widgetsPages || []),
            ...(groupPages || []),
        ].map(page => ({
            ...page,
            url: buildURL(page, pagesGroupChildTypes[page.__typename])
        }))
        
        this.state = {
            widget: {title, slug, headerImage, image, imagePosition, userSection},
            pages: sortBy(pages, 'title')
        }
    }

    render() {
        const { widget, pages } = this.state
        if (!widget) {
            return null
        }

        const { className } = this.props
        const { title, image, imagePosition, headerImage } = widget
        const position = imagePosition ? imagePosition.toLowerCase() : 'left'

        const backgroundImage = has(image, 'file.url') ? `url('${image.file.url}')` : has(headerImage, 'file.url') ? `url('${headerImage.file.url}')` :'none'
        const backgroundImageTitle = has(image, 'title') ? image.title : has(headerImage, 'title') ? headerImage.title :''

        // We only show the first 2 pages because we have a "Show all" link.
        const firstPages = pages.slice(0, 4)
        const indexUrl = buildURL(widget, CONTENTFUL_GRAPHQL_TYPES.PAGES_GROUP)

        return (
            <div className={`pages-group position-${position} ${className}`}>
                <div className="image" role="img" aria-label={backgroundImageTitle} style={{ backgroundImage }} />
                <div className="content">
                    <header>
                        <h3 className="title">{title}</h3>{' '}
                        <Link to={indexUrl} className="see-all see-all-top" onClick={() => EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.PAGES_GROUP, EventTracker.EVENT_TYPES.CLICK, 'SEE_ALL')}>
                            See all <i className="fas fa-chevron-right" />
                        </Link>
                    </header>

                    <div className="links">
                        {map(firstPages, page => (
                            <Link key={page.slug} to={page.url} onClick={() => EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.PAGES_GROUP, EventTracker.EVENT_TYPES.CLICK, 'LINK')}>
                                {page.title}
                            </Link>
                        ))}
                    </div>

                    <Link to={indexUrl} className="see-all see-all-bottom" onClick={() => EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.PAGES_GROUP, EventTracker.EVENT_TYPES.CLICK, 'SEE_ALL')}>
                        See all <i className="fas fa-chevron-right" />
                    </Link>
                </div>
            </div>
        )
    }
}
