import React from 'react'
import get from 'lodash/get'

export default (props) => {
    const { information } = props

    const text = get(information, 'text')
    const url = get(information, 'url')

    return (
        <a href={url} target="_blank" rel="noopener noreferrer" className="link file">
            <span className="icon-box">
                <i className="far fa-link icon" />
            </span>
            <span className="content-box">
                {text}
            </span>
        </a>
    )
}