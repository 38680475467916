import React from 'react'
import Moment from 'react-moment'
import Panel from './../common/Panel'
import map from 'lodash/map'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { FORMAT } from '../../variables'

export default (props) => {
    const { guideline } = props;

    if(!guideline || isEmpty(guideline.authors)) {
        return null
    }
    
    const authors = map(guideline.authors, author => `${author.firstName} ${author.lastName}`).join(', ')

    return (
        <Panel title="Document Control" className="document-control" id="document-control">
            <div className="row">
                <div className="col col-sm-12 col-md-6">
                    <div className="item">
                        <label>Date last published: </label> <span className="text"><Moment format={FORMAT.DATE}>{guideline.publishDate}</Moment></span>
                    </div>
                    <div className="item">
                        <label>Document type: </label> <span className="text">{guideline.documentType}</span>
                    </div>
                    {guideline.serviceProvider && <div className="item">
                        <label>Service Provider: </label> <span className="text">{guideline.serviceProvider.name}</span>
                    </div>}
                    {guideline.reviewFrequency && <div className="item">
                        <label>Review frequency: </label> <span className="text">{guideline.reviewFrequency} year(s)</span>
                    </div>}
                </div>
                <div className="col col-sm-12 col-md-6">
                    {authors.length > 0 && <div className="item">
                        <label>Author(s): </label> <span className="text">{authors}</span>
                    </div>}
                    {guideline.owner && <div className="item">
                        <label>Owner: </label> <span className="text">{get(guideline, 'owner.firstName')} {get(guideline, 'owner.lastName')}</span>
                    </div>}   
                    {guideline.editor && <div className="item">
                        <label>Editor: </label> <span className="text">{get(guideline, 'editor.firstName')} {get(guideline, 'editor.lastName')}</span>
                    </div>}            
                </div>
            </div>
        </Panel>
    )
}