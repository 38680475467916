import React, { PureComponent } from 'react'
import {Collapse} from 'react-collapse'

export default class Panel extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isOpen: true,
        }
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        const { isOpen } = this.state
        const { title, children, className, id } = this.props
        return (
            <div id={id} className={['panel', className].filter(Boolean).join(' ')}>
                <div className="panel-title" onClick={this.toggle}>
                    <h3>{title}</h3>
                    <div className="plus-btn">
                        <i className={`far fa-${isOpen ? 'minus' : 'plus'} icon`} />
                    </div>
                </div>

                <Collapse className="panel-content" isOpened={isOpen}>
                    <div className="inner">
                        {children}
                    </div>
                </Collapse>
            </div>
        );
    }
}