import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import Moment from 'react-moment'
import { Link } from 'gatsby'
import DocumentControl from './DocumentControl'
import GuidelineFeedback from './GuidelineFeedback'
import { FORMAT } from '../../variables'
import RelatedInformation from './relatedInformation/RelatedInformation'
import { withScrollContext, withGuidelineContext, withPopupContext } from '../../contexts'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Category from './Category'
import lightenDarkenColor from '../../utils/colour'
import { 
    MobileFloatingBar,
    GuidelineIndexList as IndexList 
} from './index'

class GuidelineContent extends PureComponent {
    state = {
      showDisclaimer: false,
      isLoading: true,
    }

    componentDidMount() {
        const config = { attributes: false, childList: true, subtree: false }

        this.contentChangeObserver = new MutationObserver(this.initializeGallery)
        this.contentChangeObserver.observe(this.guidelineContent, config)

        setTimeout(() => {
            this.setState({ isLoading: false })
        }, 100)
    }

    initializeGallery = () => {
        const { galleries } = this.props.guidelineContext
        const sectionName = this.props.location.hash === '' ? null : this.props.location.hash.replace('#', '')

        forEach(galleries, (gallery, index) => {
            ReactDOM.render(gallery, document.getElementById(`gallery-${index}`))
        })

        if(sectionName) {
            window.location.href = this.props.location.href
        }
    }

    componentWillUnmount() {
        this.setState({ isLoading: true })
        this.contentChangeObserver.disconnect()
    }

    render() {
        const { preview, draftStyle, guidelineContext, popupContext, scroll, activeSection } = this.props
        const { isLoading } = this.state
        const guideline = guidelineContext.info
        const guidelineMetaDescription = get(guideline, 'metaDescription.metaDescription')

        const { categories } = guideline

        const category = get(categories, '[0]')

        const categoryColour = category && category.colour

        const bgColourLight = categoryColour && lightenDarkenColor(categoryColour, 20)
        const bgColourDark = categoryColour && lightenDarkenColor(categoryColour, -20)
        const textColour = categoryColour && lightenDarkenColor(categoryColour, 100)

        const bgColourStyle = {backgroundColor: categoryColour}

        const bgStyle = !isLoading && preview ? {...bgColourStyle, ...draftStyle} : bgColourStyle

        // create guidelineType HTML
        const guidelineTypeHtml = guideline.guidelineType && <Link to={`/guidelines/browse/?type=${encodeURIComponent(guideline.guidelineType.guidelineType.toLowerCase().replace(/ /g, '_'))}`} style={{backgroundColor: bgColourLight}}>{guideline.guidelineType.guidelineType}</Link>

        // create category HTML
        const categoryItemsHtml = guideline.categories && guideline.categories.map((category) =>
            <Link to={`/guidelines/browse/?cat=${encodeURIComponent(category.name.toLowerCase().replace(/ /g, '_'))}`} key={category.name} style={{backgroundColor: bgColourLight}}>{category.name}</Link>
        )

        // create service provided HTML
        const serviceProviderHtml = guideline.serviceProvider && <Link to={`/guidelines/browse?sp=${guideline.serviceProvider.slug}`} style={{backgroundColor: bgColourLight}}>{guideline.serviceProvider.name}</Link>
        return (
            <div className="content">
                <Helmet>
                    <title>{guideline.title}</title>
                    {(!!guidelineMetaDescription || !!guideline.htmlSummary) && <meta name="description" content={guidelineMetaDescription || guideline.htmlSummary.replace(/(<([^>]+)>)/ig,"")} />}
                </Helmet>
                <div className="wrapper">
                    <div className={`guideline-header${isLoading ? '' : ' loaded'}`} style={bgStyle}>
                        <div className="tags">
                            {serviceProviderHtml}
                            {categoryItemsHtml}
                            {guidelineTypeHtml}
                        </div>

                        <h1>{guideline.title}</h1>

                        <div className="date" style={{color: textColour}}>
                            Date last published: <Moment format={FORMAT.DATE}>{guideline.publishDate}</Moment>
                        </div>

                        <div className="summary">
                            <div dangerouslySetInnerHTML={{
                                __html: guideline.htmlSummary
                            }}/>
                        </div>
                        <div className="disclaimer" style={{color: textColour}}>
                            This document is only valid for the day on which it is accessed. Please read our <button className="as-link" style={{borderColor: textColour}} onClick={() => popupContext.togglePopup(popupContext.POPUP_ID.DISCLAIMER)}>disclaimer</button>.
                        </div>
                    </div>

                    <Category bgColour={bgColourDark} category={category} />

                    <MobileFloatingBar isFixedIndex={scroll.isFixedIndex} isHeaderOpen={scroll.isHeaderOpen} activeSection={activeSection}>
                        <IndexList
                            className={'innerWrapper'}
                            trim={true}
                        />
                    </MobileFloatingBar>

                    <div className="content-body">
                        <div className="guideline-content clearfix" ref={el => this.guidelineContent = el}>
                            { guidelineContext.sections }
                        </div>

                        <div className={`guideline-footer${isLoading ? '' : ' loaded'}`}>
                            <RelatedInformation
                                title={'References'}
                                className={'references light-theme'}
                                id={'references'}
                                content={guideline.references}
                            />
                            <RelatedInformation
                                title={'You might also be interested in…'}
                                id={'related-information'}
                                items={guideline.relatedInformation}
                            />
                            <DocumentControl guideline={guideline} />

                            <GuidelineFeedback />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withPopupContext(withGuidelineContext(withScrollContext(GuidelineContent)))

