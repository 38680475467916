import React from 'react'
import { graphql, navigate } from 'gatsby'
import map from 'lodash/map'


export default class ImpactWidget extends React.Component {

    render() {
        const { title, introduction, items, link } = this.props.data

        return (
            <div className="impact-area">
                <div className="wrapper">
                    <div className="heading">
                        <h2>{title}</h2>
                        <div className="intro">{introduction.introduction}</div>
                    </div>
                    <ul className="impact-list">
                        {map(items, item => (
                            <li key={item.text}>
                                <a href={item.url}>
                                    <div className="content">
                                        <div className="icon-circle" style={{backgroundColor: item.colour}}>
                                            <img src={item.icon.file.url} alt={item.text} title={item.text} />
                                        </div>
                                        <span style={{color: item.colour}}>{item.text}</span>
                                    </div>
                                </a>
                            </li>
                        ))}
                    </ul>
                    <div className="btn-group">
                        <button className="btn btn-primary" onClick={() => this.goto(link.url)}>{link.text}</button>
                    </div>
                </div>
            </div>
        )
    }

    goto = (url) => {
        if(url.startsWith('http://' || url.startsWith('https://'))) {
            window.location.href = url
        } else {
            navigate(url)
        }
    }
}

export const query = graphql`
    fragment ImpactWidget on ContentfulWidgetImpact {
        title,
        introduction {
            introduction
        }
        items {
            text,
            url,
            icon {
                file {
                    url
                }
            }
            colour
        }
        link {
            text,
            url
        }
    }
`