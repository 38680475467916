import React from 'react'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import RelatedInformationContent from './RelatedInformationContent'
import RelatedInformationItems from './RelatedInformationItems'

export default (props) => {
    const { content, items } = props;
    if(content && !isEmpty(get(content, 'childContentfulRichText.html'))) {
        return <RelatedInformationContent {...props} />
    }

    if(items && items.length) {
        return <RelatedInformationItems {...props} />
    }

    return null;
}