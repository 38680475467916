import React from 'react'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { withGuidelineContext } from '../../contexts/GuidelineContext'
import InformationItem from '../common/InformationItem'

export default withGuidelineContext((props) => {
    const guideline = props.guidelineContext.info;

    if(isEmpty(guideline.forms)) {
      return null;
    }

    return (
      <div className="col">
        {map(guideline.forms, (form, i) => (
          <div key={form.text || form.title + i} className="row">
            <InformationItem information={form} />
          </div>
        ))}
      </div>
    )
})