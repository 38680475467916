import React, { useEffect, useState, memo, useCallback } from 'react';
import { FacebookEmbed } from 'react-social-media-embed';
import Loader from './Loader';
import lambdaAPI from '../../lambdaAPI';
import MasonryWidget from './MasonryWidget';
import Masonry from 'react-masonry-css';

const FacebookWidget = props => {
  const [posts, setPosts] = useState([]);
  const [limit, setLimit] = useState(6);
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    850: 1,
  };
  useEffect(() => {
    lambdaAPI.getmetaPosts(props.userSection, 'facebook', limit).then(data => {
      if (data.statusCode === 200) {
        setPosts(data.body.posts.data);
      }
    }).catch((error) => {

    })
    setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
  }, [props.userSection])

  const loadMore = useCallback(() => {
    setLoading(true);
    const l = limit + 3;
    setLimit(l);
    lambdaAPI.getmetaPosts(props.userSection, 'facebook', l).then(data => {
      if (data.statusCode === 200) {
        setPosts(data.body.posts.data);
      }
    }).catch(error => {
      setLimit(limit - 3);
    }).finally(() => {
      setLoading(false);
    });
  })

  return (
    !isMobile && posts.length ?
      <div className='facebook'>
        <h2>Read latest posts - Facebook</h2>
        <div className='facebook-posts'>
          <Masonry breakpointCols={breakpointColumnsObj} className="masonry-grid" columnClassName="masonry-grid_column">
            {posts.map((post, index) => (
              <div key={index} className='facebook-post'><FacebookEmbed url={post.permalink_url} width={isMobile ? 'auto' : 420} /></div>
            ))}
          </Masonry>
          {/* <MasonryWidget data={posts} element={(value) => <div className='facebook-post'><FacebookEmbed url={value.permalink_url} width={isMobile ? null : 420} /></div>} /> */}
        </div>
        <Loader title="Load more posts" loading={loading} loadMore={loadMore} />
      </div>
    : <></>
  )
}

export default memo(FacebookWidget);
