import React from 'react'
import map from 'lodash/map'
import { Link } from 'gatsby'
import { buildURL, CONTENTFUL_GRAPHQL_TYPES } from '../../utils/url'
import EventTracker from './../../helpers/EventTracker'

const buildLink = (item, forcedType = null, attrs) => {
    if (item.__typename === CONTENTFUL_GRAPHQL_TYPES.LINK || item.__typename === CONTENTFUL_GRAPHQL_TYPES.LINK_PTP) {
        return (
            <a href={item.url} key={item.url} target="_blank" {...attrs} rel="noopener noreferrer">
                {item.text}
            </a>
        )
    }

    const url = buildURL(item, forcedType)
    return (
        <Link key={url} to={url} {...attrs}>
            {item.title}
        </Link>
    )
}

export default (props) => {
    const { links, image, title, backgroundColor, textColor, imagePosition, description } = props.data
    const color = textColor || ''
    const col = imagePosition === 'left' ? 'col-md-12 col-lg-8' : 'col'
    return (
        <div className={`links-widget ${col} links-widget-col direction-${imagePosition}`}>
            <div style={{ borderRadius: 4 }} className={`links-widget-container position-${imagePosition}`}>
                <div className="image" role="img" aria-label={image?.title} style={{ backgroundImage: `url('${image?.file?.url}')` }} />
                <div className="link-widget-content" style={{ backgroundColor: backgroundColor || '' }}>
                    <header>
                        <h3 className="title" style={{ color }}>
                            {title}
                        </h3>
                    </header>

                    <div className="links">
                        {description && description.description && <p style={{ color }}>{description.description}</p>}
                        {map(links, (link) =>
                            buildLink(link, null, {
                                style: {
                                    color,
                                },
                                onClick: () => EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.LINKS, EventTracker.EVENT_TYPES.CLICK),
                            })
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
